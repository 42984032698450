import firebase from 'firebase/app'
import 'firebase/auth'

// The configuration below is not sensitive data. You can serenely add your config here
const config = {
  apiKey: 'AIzaSyCklPcftES96vnaLeYbNa_sd1SWeW449-0',
  authDomain: 'background-studio.firebaseapp.com',
  projectId: 'background-studio',
  storageBucket: 'background-studio.appspot.com',
  messagingSenderId: '435798530689',
  appId: '1:435798530689:web:6528f52070d4dd6eaf8b80',
  measurementId: 'G-74MFD9LN7V'
}

firebase.initializeApp(config)
