<template>
  <div class="flex">
    <div
      class="px-8 py-1 max-w-md sm:max-w-xl mx-auto lg:w-1/2 lg:max-w-full lg:py-24"
    >
      <div class="xl:max-w-lg xl:ml-auto">
        <h1 class="home-page-title font-bold py-6 text-3xl text-gray-500">
          {{ appTitle }}
        </h1>
        <img
          alt="example-background"
          class="rounded-xl shadow-xl sm:h-64 sm:w-full sm:object-cover sm:object-center lg:hidden"
          src="@/assets/img/hero-background.png"
        />

        <h1
          class="mt-6 text-2xl font-bold text-gray-900 leading-tight sm:mt-8 sm:text-4xl lg:mt-0"
        >
          You can work from anywhere.
          <br />
          <span class="text-indigo-500">Make it your space.</span>
        </h1>
        <p class="mt-2 text-green-800 sm:text-xl sm:mt-4">
          Background Studio helps you create your own personalized backgrounds
          so you can express yourself without sharing your roommates' mess.
        </p>
        <div class="mt-4 sm:mt-6">
          <router-link
            class="text-sm inline-block bg-indigo-600 text-white px-5 py-3 rounded-lg shadow-xl uppercase tracking-wider font-semibold sm:text-base hover:bg-indigo-500"
            to="/products"
            >Create Your Background</router-link
          >
        </div>
      </div>
    </div>
    <div class="hidden lg:block lg:w-1/2 lg:relative">
      <img
        alt="example-background"
        class="absolute inset-0 h-full w-3/4 lg:object-cover rounded-xl shadow-xl"
        src="@/assets/img/hero-background.png"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  head() {
    return {
      title: {
        inner: 'Home'
      },
      meta: [
        {
          name: 'description',
          content: `${this.appTitle} home page`,
          id: 'desc'
        }
      ]
    }
  },
  computed: mapState('app', ['appTitle'])
}
</script>

<style lang="scss" scoped>
// @import '@/theme/variables.scss';

// .page-wrapper {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;

//   .logo {
//     margin-bottom: 3rem;
//   }

//   .hero-image {
//     max-width: 100%;
//   }

//   .home-page-title {
//     text-align: center;
//   }

//   .documentation-link {
//     display: inline-block;
//     font-size: 1.2rem;
//     color: #fff;
//     background-color: #5d6788;
//     padding: 0.8rem 1.6rem;
//     border-radius: 4px;
//     transition: background-color 0.1s ease;
//     box-sizing: border-box;
//     text-decoration: none;
//     width: fit-content;
//     font-weight: 500;
//   }
// }
//
</style>
